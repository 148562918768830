import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as style from 'styles/components/account/membershipCard.module.scss';
import FormattedMessage from 'components/utils/FormattedMessage';

import flagGIF from '../../images/animatedFlag.gif';

const MembershipCardItem = ({ membershipCardData, membershipCardName, membershipNumber, userEmail }) =>  {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCard = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div role="button" aria-hidden="true" tabIndex={0} className={style.memberCard} onClick={toggleCard}>
            {!isOpen &&
                <div className={style.membershipCard__wrapperFront}>
                    <div className={`${style.membershipCard__card} ${style.membershipCard__front}`}>
                        <div className={style.membershipCard__content}>
                            <div className={style.membershipCard__cardHeader}>
                                <div className={style.membershipCard__logo}>
                                    <StaticImage
                                        src="../../images/hammarbyLogoColor.png"
                                        alt="Hammarby Fotboll"
                                        loading="lazy"
                                    />
                                </div>
                                <div className={style.membershipCard__title}>
                                    <h2><FormattedMessage id="account.membership.membershipCard" /></h2>
                                    <span>status</span> aktiv
                                </div>
                            </div>
                            <div className={style.membershipCard__validInfo}>
                                <div><FormattedMessage id="account.membership.validThrough" /></div>
                                <div>{membershipCardData.validTo}</div>
                            </div>
                            <div className={style.membershipCard__memberInfo}>
                                <div>
                                    {membershipCardData.title}
                                </div>
                                <div>
                                    {membershipCardName}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isOpen &&
                <div className={style.membershipCard__wrapperBack}>
                    <div className={`${style.membershipCard__card} ${style.membershipCard__back}`}>
                        <div className={style.membershipCard__stripe} />
                        <div className={style.membershipCard__chipWrapper}>
                            <div className={style.membershipCard__chip}>
                                <img src={flagGIF} alt="Animated Hammarby flag" />
                            </div>
                        </div>
                        <div className={style.membershipCard__content}>
                            <div className={style.membershipCard__memberInfoBack}>
                                <div>
                                    {membershipCardName}
                                </div>
                                {membershipNumber && (
                                <div>
                                    {membershipNumber}
                                </div>
                                )}
                                <div>
                                    {userEmail}
                                </div>
                            </div>
                        </div>
                        <div className={style.membershipCard__validInfoBack}>
                            <div><FormattedMessage id="account.membership.validThrough" /></div>
                            <div>{membershipCardData.validTo}</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
export default MembershipCardItem;
