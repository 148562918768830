// extracted by mini-css-extract-plugin
export var memberCard = "membershipCard-module--memberCard--nnbza";
export var membershipCard__back = "membershipCard-module--membershipCard__back--jThJV";
export var membershipCard__card = "membershipCard-module--membershipCard__card--83GQN";
export var membershipCard__cardHeader = "membershipCard-module--membershipCard__cardHeader--PMKHE";
export var membershipCard__chip = "membershipCard-module--membershipCard__chip--gz8er";
export var membershipCard__chipWrapper = "membershipCard-module--membershipCard__chipWrapper--Hx+GT";
export var membershipCard__content = "membershipCard-module--membershipCard__content--8NndE";
export var membershipCard__front = "membershipCard-module--membershipCard__front--kn3FU";
export var membershipCard__logo = "membershipCard-module--membershipCard__logo--MLLqs";
export var membershipCard__memberInfo = "membershipCard-module--membershipCard__memberInfo--kwSZ+";
export var membershipCard__memberInfoBack = "membershipCard-module--membershipCard__memberInfoBack--Xzz2y";
export var membershipCard__stripe = "membershipCard-module--membershipCard__stripe--eotOJ";
export var membershipCard__title = "membershipCard-module--membershipCard__title--3w25t";
export var membershipCard__validInfo = "membershipCard-module--membershipCard__validInfo--WVS-C";
export var membershipCard__validInfoBack = "membershipCard-module--membershipCard__validInfoBack--9PmYF";
export var membershipCard__wrapperBack = "membershipCard-module--membershipCard__wrapperBack--C0Rpa";
export var membershipCard__wrapperFront = "membershipCard-module--membershipCard__wrapperFront--FtlxO";