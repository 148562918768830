import React, { useContext, useEffect, useState } from 'react';

import * as style from 'styles/components/account/accountLayout.module.scss';
import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import fetchAPI from 'data/api';
import MembershipCardItem from 'components/account/MembershipCardItem';
import AccountLayout from 'components/account/Layout';
import MembershipEmailRegister from 'components/account/MembershipEmailRegister';
import AccountBanner from 'components/account/Banner';
import useAccountBanners from 'data/queries/useAccountBanners';
import Loader from 'components/utils/Loader';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const AccountMembership = ({ pageContext }) => {
    const { lang } = useContext(LangContext);
    const { isSignedIn, user } = useAuth();

    const [allMemberships, setAllMemberships] = useState([]);
    const [isNoMembership, setIsNoMembership] = useState(null);

    const [isErrorLoadingMemberships, setIsErrorLoadingMemberships] = useState(false);

    const banners = useAccountBanners();
    const FilteredBanners = banners?.filter(bannerForFilter =>
        bannerForFilter.pages.some(page => page.slug.current === pageContext.page.slug.current),
    );

    const [loading, setLoading] = useState(false);

    // Get memberships information
    useEffect(() => {
        if (!isSignedIn) return;
        // The aws api will check for memberships based on cognito info in this order
        // 1. custom:membershipEmail
        // 2. email
        // We explicit check for custom:membershipEmail to avoid any issues
        if(!user.attributes['custom:membershipEmail']) return;

        setLoading(true);

        fetchAPI(process.env.GATSBY_API_MEMBERSHIPS, user?.signInUserSession.idToken.jwtToken)
            .then((memberships) => {
                // const availableMemberships = memberships;
                const queueArray = Array.isArray(memberships) ? memberships : [memberships];
                const availableMembershipsData = queueArray
                    .map(memberData => ({
                        ...memberData,
                    }));
                setAllMemberships(availableMembershipsData);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsErrorLoadingMemberships(true);
                setLoading(false);
            });

    }, [isSignedIn], pageContext);

    useEffect(() => {
        if (!allMemberships) return;

        if (allMemberships.length === 0 || allMemberships.status === 400 ) {
            setIsNoMembership(true);
            return;
        }

        // Todo - check if there is a membership card status 20

        setIsNoMembership(false);

    }, [allMemberships]);

    return (
        <AccountLayout pageContext={pageContext}>
            {isSignedIn && (
                <>
                    {loading && <Loader message={getFormattedMessage('account.membership.loading', lang)} />}

                    {allMemberships.membershipStatus && allMemberships.membershipStatus === 20 && allMemberships.memberCards.length === 0 && (
                        <div className={style.account__errorTicketsBox}>
                            <p>
                                <span>Ett fel uppstod vid inläsningen av ditt medlemskort. Försök igen senare.</span>
                            </p>
                        </div>
                    )}

                    {/* List all memberships cards under membership */}

                    {allMemberships.length > 0 && allMemberships.map((membership, index) => (
                        <div key={index}>
                            {membership.membershipStatus === 20 && membership.memberCards.map((data, subindex) => (
                                <div key={subindex}>
                                    <MembershipCardItem membershipCardData={data} membershipCardName={membership.memberFullName} membershipNumber={membership.memberNummer} userEmail={user.attributes['custom:membershipEmail']}/>
                                </div>
                            ))}
                        </div>
                    ))}

                    {/* No memberships found */}

                    {isNoMembership && (
                        <MembershipEmailRegister />
                    )}

                    {/* Display Error message */}
                    {isErrorLoadingMemberships && (
                        <div className={style.account__errorTicketsBox}>
                            <p>
                                <span><FormattedMessage id="account.membership.errorLoadingMembership" /></span>
                            </p>
                        </div>
                    )}

                    {!allMemberships && allMemberships.length === 0 && FilteredBanners && FilteredBanners.map(banner =>
                        <div key={banner.id}>
                            <AccountBanner banner={banner} />
                        </div>,
                    )}
                </>
            )}
        </AccountLayout>
    );
};

export default AccountMembership;
